<template>
    <div class="details-container">
        <template v-for="(intersection, index) in intersectionList">
            <st-section
                :key="`${index}`"
                collapsible
                isCollapsed
                :collapsibleId="`${index}_id`"
                :class="[index == '0' ? 'expanded' : '']"
                marginTopSize="0"
            >
                <template #header>
                    <span>{{ $t("GIS_INTERSECTIONS.INTERSECTION_SECTION_TITLE", [index + 1]) }}</span>
                </template>
                <template #body>
                    <div class="row">
                        <div class="col-md-12 mb-2">
                            <span class="font-weight-bold">
                                {{ $t("GIS_INTERSECTIONS.CARD.METADATA") }}:
                            </span>
                            <span>
                                {{ intersection.metadata_name }}
                            </span>
                        </div>
                        <div class="col-md-12 mb-2">
                            <span class="font-weight-bold">
                                {{ $t("GIS_INTERSECTIONS.CARD.LAYER_NAME") }}:
                            </span>
                            <span>
                                {{ intersection.layer_name }}
                            </span>
                        </div>
                        <div class="col-md-12 mb-2">
                            <span class="font-weight-bold">
                                {{ $t("GIS_INTERSECTIONS.CARD.SUBLAYER_NAME") }}:
                            </span>
                            <span>
                                {{ intersection.sublayer_name }}
                            </span>
                        </div>
                        <div class="col-md-12 mb-2">
                            <span class="font-weight-bold">
                                {{ $t("GIS_INTERSECTIONS.CARD.ATTRIBUTE_NAME") }}:
                            </span>
                            <span>
                                {{ intersection.attribute_name }}
                            </span>
                        </div>
                        <div class="col-md-12 mb-2">
                            <span class="font-weight-bold">
                                {{ $t("GIS_INTERSECTIONS.INTERSECTION_TYPE_LABEL") }}:
                            </span>
                            <span>
                                {{ intersectionType(intersection) }}
                            </span>
                        </div>
                        <div class="col-md-12 mb-2">
                            <span class="font-weight-bold">
                                {{ $t("GIS_INTERSECTIONS.COMPLETION_METHOD_LABEL") }}:
                            </span>
                            <span>
                                {{ completionMethod(intersection) }}
                            </span>
                        </div>
                        <div class="col-md-12 mb-2">
                            <span class="font-weight-bold">
                                {{ $t("GIS_INTERSECTIONS.INTERSECTION_VALUE") }}:
                            </span>
                            <span>
                                {{ intersection.value }}
                            </span>
                        </div>
                    </div>
                </template>
            </st-section>
            <hr :key="`${index}-hr`">
        </template>
    </div>
</template>

<script>

const INTERSECTION_TYPE_TOTAL = 'total';
const INTERSECTION_TYPE_PARTIAL = 'partial';
const INTERSECTION_TYPE_NONE = 'none';

export default {
    name: "GisIntersectionsViewDetails",
    props: {
        item: {
            type: Object,
        },
    },
    data() {
        return {
            itemValueList: [],
        };
    },
    computed: {
        intersectionList() {
            return this.item?.values;
        }
    },
    methods: {
        completionMethod(item) {
            if (!item?.completion_method) return '';

            return item?.completion_method === 'manual' ?
                this.$t("GIS_INTERSECTIONS.METADATA_COMPLETION_METHOD.MANUAL"):
                this.$t("GIS_INTERSECTIONS.METADATA_COMPLETION_METHOD.USE_GIS_VALUE");
        },
        intersectionType(item) {
            let iTypeStr = '';
            switch(item?.intersection_type) {
                case INTERSECTION_TYPE_TOTAL:
                    iTypeStr = this.$t("GIS_INTERSECTIONS.INTERSECTION_TYPE.TOTAL");
                    break;
                case INTERSECTION_TYPE_PARTIAL:
                    iTypeStr = this.$t("GIS_INTERSECTIONS.INTERSECTION_TYPE.PARTIAL");
                    break;
                case INTERSECTION_TYPE_NONE:
                    iTypeStr = this.$t("GIS_INTERSECTIONS.INTERSECTION_TYPE.NONE");
                    break;
            }

            return iTypeStr;
        }
    },
};
</script>
