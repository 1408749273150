<template>
    <st-section
        :header="$t('GIS_INTERSECTIONS.LIST_HEADER')"
        customClass="list-section"
        id="gis-intersections-list"
        :validate="false"
    >
        <template #body>
            <carousel-3d
                :controls-visible="true"
                :width="400"
                :height="220"
                :space="300"
                :count="itemsComputed.length"
                display="3"
                ref="gisIntersectionsCarousel"
            >
                <slide v-for="(item, i) in itemsComputed" :index="i" :key="i">
                    <div class="carousel-card real-estate-card">
                        <gis-intersections-view-item-card
                            v-if="!item.isPlaceholder"
                            :item="item"
                            :index="i"
                            :viewMode="true"
                            @view="onView"
                        ></gis-intersections-view-item-card>
                        <div class="card-user-placeholder" v-else>
                            <i class="fas fa-home"></i>
                        </div>
                    </div>
                </slide>
            </carousel-3d>
            <div class="d-flex list-footer">
                <div class="col-9 d-flex align-items-center px-0">
                    <span>{{ total }}</span>
                </div>
            </div>

            <gis-intersections-view-modal
                ref="gisIntersectionsViewModal"
                :items="items"
                :index="selectedEntityIndex"
            ></gis-intersections-view-modal>

        </template>
    </st-section>
</template>

<script>
import { mapGetters } from "vuex";
import { Carousel3d, Slide } from "vue-carousel-3d";
import { groupBy } from '@/shared/utils/groupby';
import GisIntersectionsViewModal from '@/modules/applications/components/gis-intersections/GisIntersectionsViewModal.vue';
import GisIntersectionsViewItemCard from '@/modules/applications/components/gis-intersections/GisIntersectionsViewItemCard.vue';

export default {
    name: "GisIntersections",
    components: {
        GisIntersectionsViewModal,
        GisIntersectionsViewItemCard,
        Carousel3d,
        Slide,
    },
    props: {
        applicationType: {
            type: String,
        },
    },
    data() {
        return {
            intersections: null,
            items: [],
            selectedEntity: null,
            selectedEntityIndex: null,
            cadastralNumber: [],
        };
    },
    computed: {
        ...mapGetters({
            record: "applications/form/record",
        }),
        total() {
            return this.items.length === 1
                ? this.$t("GIS_INTERSECTIONS.SINGLE", { total: 1 })
                : this.$t("GIS_INTERSECTIONS.MULTIPLE", { total: this.items.length });
        },
        itemsComputed() {
            const countItems = this.items?.length ?? 0;
            if (!countItems >= 3) return [...this.items];
            const previewItems = 3;
            let visibleItems = [];
            if (countItems > 0) {
                visibleItems = [...this.items];
            }
            const placeholderItems = previewItems - countItems;
            for (let i = 0; i < placeholderItems; i += 1) {
                visibleItems.push({
                    isPlaceholder: true,
                });
            }

            return visibleItems;
        },
    },
    methods: {
        cancel() {
            this.selectedEntity = null;
            this.selectedEntityIndex = null;
        },
        goToSlide(index) {
            this.$refs.gisIntersectionsCarousel.goSlide(index);
        },
        onView({ item, index }) {
            this.selectedEntity = item;
            this.selectedEntity.index = index;
            this.selectedEntityIndex = index;
            this.$refs.gisIntersectionsViewModal.show(true);
        },
        computeItems(intersections) {
            let resultList = []
            if(intersections) {
                Object.keys(intersections).forEach((v) => {
                    const intersection = intersections[v];

                    if(intersection.values?.length) {
                        intersection.values.forEach((bv) => {
                            resultList.push({
                                metadata_name: v,
                                tenant_name: intersection.tenant_name,
                                layer_name: intersection.layer_name,
                                sublayer_name: intersection.sublayer_name,
                                attribute_name: intersection.attribute_name,
                                intersection_type: intersection.intersection_type,
                                ...bv
                            })
                        })
                    }
                });
            }

            if(!resultList.length) return [];

            const groupedResultList = groupBy(resultList, 'cadastral_number');

            const finalItems = Object.keys(groupedResultList).map((cf) => {
                return {
                    uat: groupedResultList[cf][0].uat,
                    cadastral_number: groupedResultList[cf][0].cadastral_number,
                    values: groupedResultList[cf],
                }
            });

            return finalItems ?? [];
        }
    },
    created() {
        if (this.record) {
            const intersections = this.record?.form_staff?.gis_intersections ?? {};
            this.intersections = intersections;

            this.items = this.computeItems(intersections);
        }
    },
};
</script>
