<template>
    <div>
        <div class="row align-items-center mb-8">
            <div class="col col-10">
                <div class="font-weight-bold">{{ item.cadastral_number }}</div>
            </div>
            <div class="col col-2 p-0">
                <div>
                    <div class="card-action" @click="$emit('view', {item, index})">
                        <i class="fas fa-eye"></i>
                    </div>
                </div>
            </div>
        </div>

        <div class="mt-2">
            <span class="font-weight-bold">{{ $t("GIS_INTERSECTIONS.CARD.CADASTRAL_NUMBER") }}: </span>
            <span>{{ item.cadastral_number }}</span>
        </div>
        <div class="mt-2">
            <span class="font-weight-bold">{{ $t("GIS_INTERSECTIONS.CARD.UAT") }}: </span>
            <span>{{ item.uat }}</span>
        </div>
    </div>
</template>
<script>
export default {
    name: 'GisIntersectionsViewItemCard',
    props: {
        item: {
            type: Object,
            required: true,
        },
        index: {
            type: Number,
            required: true,
        },
        viewMode: {
            type: Boolean,
            default: true,
        },
    },
}
</script>
