<template>
    <div>
        <st-modal
            id="gis-intersections-view-modal"
            hide-header-delimiter
            hide-footer-delimiter
            hide-footer
            hideable
            size="md"
            :noCloseOnBackdrop="false"
            ref="modal"
            customClass="form-modal view-modal"
        >
            <template #header>
                <div class="w-100 d-flex pb-2 border-bottom align-items-center pb-4">
                    <div class="col-md-7 px-0">
                        <p class="font-size-h5 font-weight-bold mb-0 pb-0">
                            {{ $t("GIS_INTERSECTIONS.INTERSECTION_VIEW_MODAL_TITLE", [currentIntersection.uat, currentIntersection.cadastral_number]) }}
                        </p>
                    </div>
                    <div class="col-md-5 px-0" v-if="allowSlide">
                        <div class="d-flex justify-content-end">
                            <div class="view-item arrow left" @click="goLeft">
                                <i class="fas fa-chevron-left"></i>
                            </div>
                            <div class="view-item middle">
                                Intersectie {{ currentIndex + 1 }} din
                                {{ items.length }}
                            </div>
                            <div class="view-item arrow right" @click="goRight">
                                <i class="fas fa-chevron-right"></i>
                            </div>
                        </div>
                    </div>
                </div>
            </template>

            <template #body>
                <gis-intersections-view-details :item="currentIntersection"></gis-intersections-view-details>

                <div class="d-flex justify-content-between">
                    <st-button size="large" variant="link" :callback="hide">
                        {{ $t("APPLICATION.MODALS.CANCEL") }}
                    </st-button>
                </div>
            </template>
        </st-modal>
    </div>
</template>

<script>
import GisIntersectionsViewDetails from '@/modules/applications/components/gis-intersections/GisIntersectionsViewDetails.vue';

export default {
    name: "GisIntersectionsViewModal",
    components: {
        GisIntersectionsViewDetails
    },
    props: {
        items: {
            type: Array,
            required: false,
        },
        index: {
            type: Number,
        },
    },
    data() {
        return {
            currentIndex: 0,
        };
    },
    computed: {
        currentIntersection() {
            return this.items[this.currentIndex];
        },
        allowSlide() {
            return this.items.length > 1;
        }
    },
    methods: {
        hide() {
            this.$refs["modal"].hide();
        },
        show() {
            this.$refs["modal"].show();
        },
        goRight() {
            if (this.currentIndex + 1 < this.items.length) {
                this.currentIndex = this.currentIndex + 1;
            } else {
                this.currentIndex = 0;
            }
        },
        goLeft() {
            if (this.currentIndex + 1 >= this.items.length - 1) {
                this.currentIndex = this.currentIndex - 1;
                if (this.currentIndex < 0) {
                    this.currentIndex = 0;
                }
            } else {
                this.currentIndex = this.items.length - 1;
            }
        },
    },
    mounted() {
        // Initialize the form after the modal is shown
        this.$root.$on("bv::modal::shown", (modalObj) => {
            if (modalObj.componentId === "gis-intersections-view-modal") {
                this.currentIndex = this.index;
            }
        });
    },
};
</script>
